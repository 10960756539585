import { http } from '@/services/api/http'
import { PaginatedResponse } from './types/shared'
import saveFile from 'save-file'
import { makeDownloadFileName } from '@/utils/makeDownloadFileName'
import { Membership } from '@/api/memberships'
import { Maybe } from '@/types'
import { trimObjectFields } from '@/utils/trimObjectFields'

/* eslint-disable */
export interface Member {
  id: number
  membership: Maybe<number>
  membership_data: Maybe<Membership>
  first_name: string
  last_name: string
  company: string
  phone: string
  email: string
  young_collector: boolean
  yc_age: number
  interests: string
  source: string
  source_member: number
  sponsor_member: number
  sponsor_relationship: string
  date_created: string
  date_updated: string
  deceased: boolean
}

export type MemberOrderingField =
  | 'id'
  | 'first_name'
  | 'last_name'
  | 'company'
  | 'email'
  | 'young_collector'
  | 'yc_age'
  | 'interests'
  | 'source'
  | 'membership'
  | 'date_created'
  | 'date_updated'
  | 'deceased'

export type MemberOrderBy = `${MemberOrderingField}` | `-${MemberOrderingField}`

export type GetMembersResponse = PaginatedResponse<Member>

export type GetAllMembersResponse = Member[]

export type GetOneMemberResponse = Member

export type CreateMemberPayload = Partial<Member>

export type UpdateMemberPayload = Partial<Member>

/* eslint-enable */

const RESOURCE = '/members'

const get = async (page = 1, filters = {}, ordering: MemberOrderBy = '-id') => {
  const response = await http.get<GetMembersResponse>(`${RESOURCE}/`, {
    params: {
      page,
      ...trimObjectFields(filters),
      ordering,
    },
  })
  return response.data
}

const getAll = async (filters = {}) => {
  const response = await http.get<GetAllMembersResponse>(`${RESOURCE}/`, {
    params: {
      no_page: true,
      ...trimObjectFields(filters),
      ordering: '-id',
    },
  })

  return response.data
}

const getForAutocomplete = async (search: string) => {
  const response = await http.get<GetAllMembersResponse>(`${RESOURCE}/`, {
    params: {
      no_page: true,
      search_autocomplete: search,
      limit: 100,
    },
  })

  return response.data
}

const getOne = async (id: string) => {
  const response = await http.get<GetOneMemberResponse>(`${RESOURCE}/${id}/`)

  return response.data
}

const create = async (payload: CreateMemberPayload) => {
  const response = await http.post(`${RESOURCE}/`, trimObjectFields(payload))

  return response.data
}

const update = async (id: string, payload: UpdateMemberPayload) => {
  const response = await http.put(
    `${RESOURCE}/${id}/`,
    trimObjectFields(payload),
  )

  return response.data
}

const deleteOne = async (id: string) => {
  const response = await http.delete(`${RESOURCE}/${id}/`)

  return response.data
}

const exportToFile = async (filters = {}, ordering: MemberOrderBy = '-id') => {
  const response = await http.get(`${RESOURCE}/export/`, {
    responseType: 'blob',
    params: {
      ...trimObjectFields(filters),
      ordering,
    },
  })

  await saveFile(response.data, makeDownloadFileName('ipba-members-list'))
}

export const membersAPI = {
  get,
  getAll,
  getOne,
  getForAutocomplete,
  create,
  update,
  deleteOne,
  exportToFile,
}
